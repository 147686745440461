import React from "react";
import SessionHelper from "../helper/SessionHelper";

function isDevelopmentMode() {
    return '_self' in React.createElement('div');
}
export default class PagarmeConstants {
    static getKeys() {
        const isProduction = !isDevelopmentMode();
        const isNotTestUser = !SessionHelper.getData() ? true : SessionHelper.getData() && SessionHelper.getData().email != 'test_company2@aceapp.com.br';

        return {
            api_key: isProduction && isNotTestUser ? 'ak_live_9ArRkJWyQvqM2Gc1uyR0Obw8xpGBlV' : 'ak_test_JpyvmQjgXndcOTi0lk2G4pvRyxL8O0',
            encription_key: isProduction && isNotTestUser ? 'ek_live_7X1JnBDDz3tl1yyVNB1FrjuhMcm8p8' : 'ek_test_M4G5E3p6NQ0Lg4L5JuGosxOPjerMdl',
            easydata_recipient_id: isProduction && isNotTestUser ? 're_ck52itoj15vvvtd6487qyfhk2' : 're_ck52iz1p809il0j6dzrp0rb3d',
            easydata_transaction_amount: 160,
            easydata_transaction_amount_boleto: 50,
            easydata_transaction_amount_pix: 50,
            credit_card_tax: 3,
            credit_card_tax_2_6: 3.19,
            credit_card_tax_7: 3.39,
            boleto_tax: 315,
            pix_tax: 1.19,
            transfer_tax: 367,
            transfer_min_value: 10000,
            student_subscription_postback: isProduction && isNotTestUser ? 'https://api-bg-tennis-01.web.app/studentSubscriptionPostback' : 'https://f4be5f24c7add7cdc086449afe15d866.m.pipedream.net',
            tournament_enroll_postback: isProduction && isNotTestUser ? 'https://api-bg-tennis-01.web.app/tournament/boletoSubscriptionPostback' : 'https://f4be5f24c7add7cdc086449afe15d866.m.pipedream.net',
            ranking_enroll_postback: isProduction && isNotTestUser ? 'https://api-bg-tennis-01.web.app/ranking/boletoSubscriptionPostback' : 'https://f4be5f24c7add7cdc086449afe15d866.m.pipedream.net',
            ticket_boleto_postback: isProduction && isNotTestUser ? 'https://api-bg-tennis-01.web.app/sales/boletoTicketPaymentPostback' : 'https://f4be5f24c7add7cdc086449afe15d866.m.pipedream.net',
            company_postback: isProduction && isNotTestUser ? 'https://api-bg-tennis-01.web.app/companyPostback' : 'https://f4be5f24c7add7cdc086449afe15d866.m.pipedream.net',
            default_boleto_fine: {
                days: 1,
                percentage: 2,
            },
            default_boleto_interest: {
                days: 1,
                percentage: 4,
            },
        }
    }
}